import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '../apollo-client'
import Layout from '../components/layout'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { JobsProvider } from '../contexts/JobsContext'
import { LeadProvider } from '../contexts/LeadContext'
import { GlobalProvider } from '../contexts/GlobalContext'

import Image from 'next/image'
import { GA_TRACKING_ID } from '../lib/ga'
import Script from 'next/script'

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            background: 'none'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          minHeight: '3rem'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <script
        id="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            legalName: 'Drivers Job Choice',
            alternateName: 'DriversJobChoice.com',
            description:
              'Truck Driver Jobs – Local, Regional &amp; OTR | Drivers Job Choice tato',
            url: 'https://driversjobchoice.com/',
            logo: 'https://driversjobchoice.com/assets/img/DJC-logo.webp',
            sameAs: [
              'https://www.facebook.com/driversjobchoice',
              'https://twitter.com/driverjobchoice',
              'https://www.instagram.com/driversjobchoice/',
              'https://www.pinterest.com/driverjobchoice/',
              'https://www.youtube.com/channel/UCgv4jfNUuVj7r3Q7S6UoLDg',
              'https://www.linkedin.com/company/drivers-job-choice/'
            ]
          })
        }}
      />

      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                  n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
              })(
                window,
                document,
                "script",
                "https://connect.facebook.net/en_US/fbevents.js"
              );
              fbq("init", "481761832950938");
              fbq('track', 'PageView');`
        }}
      />
      <noscript>
        <Image
          alt="FB pageView pixel"
          height="1"
          width="1"
          src="https://www.facebook.com/tr?id=481761832950938&ev=PageView&noscript=1"
        />
      </noscript>

      <Script
        async
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="ga-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GA_TRACKING_ID}');
              `
        }}
      />

      <ThemeProvider theme={theme}>
        <ApolloProvider client={apolloClient}>
          <JobsProvider>
            <LeadProvider>
              <GlobalProvider>
                {/* TODO apply prividers only for proper pages */}
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </GlobalProvider>
            </LeadProvider>
          </JobsProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  )
}
