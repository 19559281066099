import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  PaperProps,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { ReactNode } from 'react'
import { MdClose } from 'react-icons/md'
import { LoadMask } from '../ui/LoadMask'

interface Props {
  open: boolean
  onCancel: (v: boolean) => void
  children: ReactNode
  footer?: ReactNode
  hideFooter?: boolean
  loadMask?: boolean
  footerCls?: string
  contentCls?: string
  PaperProps?: Partial<PaperProps>
  classname?: string
  closeBtnColor?: string
}

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose: (v: boolean) => void
  classname?: string
  closeBtnColor?: string
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
    //  maxWidth: '90vw'
  },
  '& .MuiPaper-root': {
    maxWidth: '95vw',
    maxHeight: '95vh',
    width: '780px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3)
  }
}))

function BootstrapDialogTitle(props: DialogTitleProps) {
  const {
    classname = 'bg-[#b3b3b3]',
    children,
    onClose,
    closeBtnColor,
    ...other
  } = props
  return (
    <DialogTitle {...other} className={classname}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose(true)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <MdClose className={closeBtnColor} />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
export default function CustomizedModal({
  open,
  onCancel,
  children,
  footer,
  hideFooter = false,
  loadMask = false,
  footerCls = '',
  contentCls = '',
  PaperProps,
  classname,
  closeBtnColor = 'text-black'
}: Props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BootstrapDialog
      scroll="paper"
      PaperProps={PaperProps}
      fullScreen={fullScreen}
      open={open} // TODO tato it is always opened no prop is needed i guess
    >
      <LoadMask active={loadMask} className="absolute" />
      <BootstrapDialogTitle
        onClose={onCancel}
        closeBtnColor={closeBtnColor}
        classname={classname}></BootstrapDialogTitle>
      <DialogContent className={`relative ${contentCls}`} dividers={true}>
        {children}
      </DialogContent>
      {!hideFooter && (
        <DialogActions className={footerCls}>{footer}</DialogActions>
      )}
    </BootstrapDialog>
  )
}
