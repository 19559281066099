import { error } from 'console'
import React, { memo, useState } from 'react'

interface Scripts {
  name: string
  src: string
}

const ScriptStore: Scripts[] = [
  {
    name: 'jobView',
    src: 'https://s3.amazonaws.com/jotrack/u5l6/job-view.js'
  },

  {
    name: 'jobApplyStart',
    src: 'https://s3.amazonaws.com/jotrack/u5l6/job-apply-start.js'
  },

  {
    name: 'jobApply',
    src: 'https://s3.amazonaws.com/jotrack/u5l6/job-apply.js'
  },
  {
    name: 'jobs2careers',
    src: 'https://www.jobs2careers.com/conversion2.php?p=8896'
  }
]

const initScriptsVal: any = {}
ScriptStore.forEach((script: any) => {
  initScriptsVal[script.name] = {
    loaded: false,
    src: script.src
  }
})
interface CustomScriptElement extends HTMLScriptElement {
  readyState: string
  onreadystatechange: any
}

interface IGlobalContext {
  mobileFilterActive: boolean
  setMobileFilterActive: (v?: boolean) => void
  setErrorMsg: (msg: string) => void
  scripts: any
  loadScripts: (...scripts: string[]) => Promise<any>
  loadImgScript: (name: string) => Promise<any>

  errorMsg: string
}

const initialState: IGlobalContext = {
  mobileFilterActive: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setMobileFilterActive: (_?: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  scripts: initScriptsVal,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  loadScripts: (..._: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise(() => {})
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  loadImgScript: (_: string) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise(() => {})
  },
  errorMsg: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setErrorMsg: (_: string) => {}
}

const GlobalContext = React.createContext<IGlobalContext>(initialState)

const globalProvider = memo((props: any) => {
  const [state, setState] = useState({
    ...initialState,

    setMobileFilterActive: (v?: boolean) => {
      setState((state) => ({
        ...state,
        mobileFilterActive:
          typeof v === 'boolean' ? v : !state.mobileFilterActive
      }))
    },

    setErrorMsg: (msg: string) => {
      setState((state) => ({
        ...state,
        errorMsg: msg
      }))
    },

    loadScripts(...scripts: string[]) {
      const promises: any[] = []
      scripts.forEach((script) => promises.push(state.loadScript(script)))
      return Promise.all(promises)
    },

    loadScript(name: string) {
      return new Promise((resolve) => {
        //resolve if already loaded
        if (state.scripts[name].loaded) {
          resolve({ script: name, loaded: true, status: 'Already Loaded' })
        } else if (state.scripts[name].loading) {
          resolve({ script: name, loaded: true, status: 'Loading' })
        } else {
          state.scripts[name].loading = true
          //load script
          const script = document.createElement('script') as CustomScriptElement
          script.type = 'text/javascript'
          script.src = state.scripts[name].src
          if (script.readyState) {
            //IE
            script.onreadystatechange = () => {
              if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
              ) {
                script.onreadystatechange = null
                state.scripts[name].loaded = true
                resolve({ script: name, loaded: true, status: 'Loaded' })
              }
            }
          } else {
            //Others
            script.onload = () => {
              state.scripts[name].loaded = true
              resolve({ script: name, loaded: true, status: 'Loaded' })
            }
          }
          script.onerror = () =>
            resolve({ script: name, loaded: false, status: 'Loaded' })
          document.getElementsByTagName('head')[0].appendChild(script)
        }
      })
    },

    loadImgScript(name: string) {
      return new Promise((resolve) => {
        //resolve if already loaded
        if (state.scripts[name].loaded && state.scripts[name].ref) {
          state.scripts[name].ref.parentElement.removeChild(
            state.scripts[name].ref
          )
        }
        //load script
        const script = document.createElement('noscript')

        const img = document.createElement('img')
        img.src = state.scripts[name].src

        img.width = 1
        img.height = 1
        script.appendChild(img)
        const ref = document.getElementsByTagName('head')[0].appendChild(script)

        state.scripts[name].loaded = true
        state.scripts[name].ref = ref

        resolve({ script: name, loaded: true, status: 'Loaded' })
      })
    }
  })

  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  )
})

globalProvider.displayName = 'globalProvider'

export const GlobalProvider = globalProvider

export default GlobalContext
