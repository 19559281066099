import apolloClient from '../apollo-client'
import { STATES } from '../constants/States'
import { SEARCH_CITIES, VALIDATE_ZIP } from '../graphql/common'
import { City, KeyValPair } from '../types/Common'
import { IncomingMessage } from 'http'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'

export const getStatesArr = () => {
  const states: KeyValPair[] = []
  for (const key in STATES) {
    states.push({
      label: STATES[key],
      value: key
    })
  }

  return states
}

//eslint-disable-next-line
export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export async function validateZip(zipCode: string): Promise<boolean> {
  const { data } = await apolloClient.query({
    query: VALIDATE_ZIP,
    variables: {
      zipCode
    }
  })

  const isValid = data.validateZip
  return isValid ?? false
}

export async function searchCities(name: string): Promise<City[]> {
  const { data } = await apolloClient.query({
    query: SEARCH_CITIES,
    variables: {
      cityInput: {
        name,
        skip: 0,
        limit: 10
      }
    }
  })

  return data.cities
}

export function searchStates(name: string) {
  name = (name || '').toLowerCase()
  const states = getStatesArr().filter(
    (s) =>
      s.value.toLowerCase().includes(name) ||
      s.label.toLowerCase().includes(name)
  )
  return states
}

// TODO change type
export function formatState(s: KeyValPair) {
  return {
    type: 'state',
    label: `${s.label} (${s.value})`,
    value: s.value
  }
}

export function toTitleCase(str = '') {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  })
}

export const FB_APP_ID = '164853005541059'

export function limitText(value: string, length: number): string {
  if (!value) {
    return ''
  }

  const biggestWord = 50
  const elipses = '...'
  if (typeof value === 'undefined') return value
  if (value.length <= length) return value
  if (length < elipses.length) return ''
  //.. truncate to about correct lenght
  let truncatedText = value.slice(0, length + biggestWord)

  //.. now nibble ends till correct length
  while (truncatedText.length > length - elipses.length) {
    const lastSpace = truncatedText.lastIndexOf(' ')
    if (lastSpace === -1) {
      truncatedText = ''
      break
    }
    truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?]$/, '')
  }
  return truncatedText + elipses
}

export function youtube_parser(text: string) {
  if (!text) {
    return []
  }
  const regExp =
    /https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/g
  const match = text.match(regExp)
  return match
}

export function parseVideoData(link = '', imgResulution = 'sddefault.jpg') {
  link = link.replace('embed/', 'watch?v=')
  if (link.includes('youtube') || link.includes('youtu.be')) {
    const searchParams = new URLSearchParams(link.substring(link.indexOf('?')))

    let videoId = searchParams.get('v')
    if (!videoId) {
      const splited = link.split('/')
      videoId = splited[splited.length - 1]
    }
    return {
      videoId,
      link,
      src: `https://img.youtube.com/vi/${videoId}/${imgResulution}`
    }
  }
}

export function getSEOData(
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
) {
  const { host, ['x-forwarded-proto']: protocol } = req.headers

  const url = `${protocol}://${host}${req.url}`
  return {
    props: {
      currentUrl: url,
      domain: new URL(url!).origin
    }
  }
}

export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN
