import React, { memo, useState } from 'react'
import { formatState, searchStates } from '../lib/Utils'
import { JobFilterForm, JobQuery } from '../types/JobPost'

interface IJobsContext {
  setStateName: (name: string) => void
  stateName: string
  filters: Partial<JobQuery>
  setFilters: (_: Partial<JobQuery>) => void

  formFilterValues: Partial<JobFilterForm>
  setFormFilterValues: (_: Partial<JobFilterForm>) => void
}

const initialState: IJobsContext = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setStateName: (_: string) => {},
  stateName: '',
  filters: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setFilters: (_: Partial<JobQuery>) => {},

  formFilterValues: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setFormFilterValues: (_: Partial<JobFilterForm>) => {}
}

const JobsContext = React.createContext<IJobsContext>(initialState)

const jobsProvider = memo((props: any) => {
  const [state, setState] = useState({
    ...initialState,
    setStateName: (stateName: string) => {
      if (stateName) {
        const states = searchStates(stateName)
        if (states.length !== 1) {
          return
        }

        const formatedState = formatState(states[0])
        state.setFormFilterValues({
          location: formatedState
        })

        state.setFilters({
          location: {
            states: [formatedState.value]
          }
        })
      }

      setState((state) => ({
        ...state,
        stateName
      }))
    },

    setFilters: (filters: Partial<JobQuery>) => {
      setState((state) => ({
        ...state,
        filters
      }))
    },

    setFormFilterValues: (formFilterValues: Partial<JobFilterForm>) => {
      setState((state) => ({
        ...state,
        formFilterValues
      }))
    }
  })

  return (
    <JobsContext.Provider value={state}>{props.children}</JobsContext.Provider>
  )
})

jobsProvider.displayName = 'jobsProvider'

export const JobsProvider = jobsProvider

export default JobsContext
