import { MdSave, MdSend } from 'react-icons/md'
import { AiFillMail } from 'react-icons/ai'
import { BsPencilFill } from 'react-icons/bs'

import apolloClient from '../../apollo-client'

import styledCmp from 'styled-components'
import { useContext, useState } from 'react'
import { SEND_EMAIL_MUTATION } from '../../graphql/email'
import CustomizedModal from './CustomizedModal'
import { Controller, useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import GlobalContext from '../../contexts/GlobalContext'

interface Props {
  open: boolean
  handleClose: (v: boolean) => void
}
type FormValues = {
  from: string
  subject: string
  body: string
}
const MAX_MESSAGE_LENGTH = 1024
const schema = yup
  .object({
    from: yup.string().label('Email').required().email(),
    subject: yup.string().label('Subject').required().max(256),
    body: yup.string().label('Message').required().max(MAX_MESSAGE_LENGTH)
  })
  .required()

export default function ContactUs({ open, handleClose }: Props) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setErrorMsg } = useContext(GlobalContext)

  const Label = (text: string) => {
    return <label className="text-[#0000008a] text-[20px]">{text}</label>
  }

  const {
    control,
    formState: { errors, isValid },
    getValues
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      from: '',
      subject: '',
      body: ''
    }
  })

  const resetForm = () => {
    setShowSuccess(false)
  }
  const btnIsDisabled = loading || !isValid

  const onSubmit = async () => {
    if (btnIsDisabled) {
      return
    }
    try {
      const values = getValues()

      setLoading(true)

      const { data } = await apolloClient.mutate({
        mutation: SEND_EMAIL_MUTATION,
        variables: {
          ...values,
          to: 'info@driversjobchoice.com'
        }
      })

      if (data.sendEmail) {
        setShowSuccess(true)
      }
    } catch (e) {
      setErrorMsg('Uh-oh! Error sending contact information. Try again soon.')
    }
    setLoading(false)
  }

  const onClose = (close: boolean) => {
    handleClose(close)
    resetForm()
  }

  return (
    <CustomizedModal
      open={open}
      loadMask={loading}
      hideFooter={showSuccess}
      footer={
        <button
          type="button"
          disabled={btnIsDisabled}
          className="disabled:bg-[#888] w-full mt-2 text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={onSubmit}>
          <div className="flex items-center content-center justify-center text-[16px] font-bold font-arial">
            <span>Contact Us</span>
            <MdSend className="ml-2" />
          </div>
        </button>
      }
      onCancel={() => onClose(false)}>
      <H1>Contact Information</H1>
      <H4>
        EMAIL: <span>info@driversjobchoice.com</span>
      </H4>

      <H1ContactUs>Contact US</H1ContactUs>
      {!showSuccess ? (
        <form
          className="w-full"
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <Box sx={{ '& > :not(style)': { mb: 1 } }}>
            <Controller
              name="from"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('Email')}
                  error={!!errors.from}
                  helperText={errors?.from?.message || ' '}
                  fullWidth={true}
                  variant="standard"
                  placeholder="ex. djc@example.com"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AiFillMail className={iconCls} />
                      </InputAdornment>
                    )
                  }}
                />
              )}></Controller>

            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  label={Label('Subject')}
                  error={!!errors.subject}
                  helperText={errors?.subject?.message || ' '}
                  fullWidth={true}
                  variant="standard"
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <BsPencilFill className={iconCls} />
                      </InputAdornment>
                    )
                  }}
                  placeholder="ex. I need help with..."
                />
              )}></Controller>

            <Controller
              name="body"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  multiline
                  error={!!errors.body}
                  inputProps={{ maxLength: MAX_MESSAGE_LENGTH }}
                  helperText={
                    errors?.body?.message ||
                    `${field.value?.length || 0}/${MAX_MESSAGE_LENGTH}`
                  }
                  label={Label('Message')}
                  maxRows={10}
                  minRows={2}
                  fullWidth={true}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MdSave className={iconCls} />
                      </InputAdornment>
                    )
                  }}
                  placeholder="ex. I want to know more about DJC..."
                />
              )}></Controller>
          </Box>
        </form>
      ) : (
        <div className="text-center text-[16px] text-[#6f6f6f] font-lato-light font-semibold">
          Thank you for your interest. A representative of driversjobchoice.com
          will contact you shortly.
        </div>
      )}
    </CustomizedModal>
  )
}
const iconCls = 'text-[1.5rem] text-[#6f6f6f]'
const H1 = styledCmp.h1`
  color: #444;
  font-size: 26px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  display: block;
`

const H4 = styledCmp.h4`
  font-size: 16px;
  color: #6f6f6f;
  font-family: Lato Light, sans-serif;

  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
`

const H1ContactUs = styledCmp.h1`
  color: #444;
  font-size: 26px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  display: block;
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
`
