type Props = {
  className: string
}
export const SkeletonCard = ({ className = '' }: Props) => {
  return (
    <>
      <div className={`flex w-full flex-1 flex-col items-center ${className}`}>
        <div className="w-full animate-pulse flex-row items-center justify-center space-x-1 px-2 ">
          <div className="flex flex-col space-y-2 ">
            <div className="h-6 w-9/12 mx-auto rounded-md bg-gray-300 "></div>
            <div className="h-6 w-full  rounded-md bg-gray-300 "></div>
            <div className="h-6 w-11/12 rounded-md bg-gray-300 "></div>
            <div className="h-6 w-10/12 rounded-md bg-gray-300 "></div>
          </div>
        </div>
      </div>
    </>
  )
}
