import { gql } from '@apollo/client'

export const GET_LEADFORM_QUERY = gql`
  query GetLeadForms($campaignId: ID) {
    leadForms(query: { campaignId: $campaignId }) {
      _id

      fields {
        _id
        label
        type
        size
        required
        searchKey
        config
        options {
          _id
          title
        }
      }
    }
  }
`

export const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: LeadInput!) {
    createLead(input: $input) {
      leadId
      isGenericLead
      client {
        confirmationText {
          failed
          success
        }
      }
      qualifiedCliens {
        client {
          name
        }
        matchedCampaign {
          title
          trackingNumbers {
            routingNumber
            trackingNumber
          }
        }
      }
    }
  }
`
