import { gql } from '@apollo/client'

export const VALIDATE_ZIP = gql`
  query validateZip($zipCode: String!) {
    validateZip(zipCode: $zipCode)
  }
`

export const SEARCH_CITIES = gql`
  query cities($cityInput: CityInput!) {
    cities(cityInput: $cityInput) {
      _id
      name
      stateCode
    }
  }
`
