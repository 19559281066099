import React, { memo, useState } from 'react'
import apolloClient from '../apollo-client'
import { CREATE_LEAD_MUTATION } from '../graphql/leadForm'
import { LeadFormStaticData, LeadSubmitResult } from '../types/LeadForm'

interface ILeadContext {
  submitLead: (leadData: any) => Promise<void>
  reset: () => void
  leadFormStaticData: LeadFormStaticData | null
  setLeadFormStaticData: (data: LeadFormStaticData) => void

  invalidZipCodeError: string
  invalidEmailError: string
  submitLeadResultData: Partial<LeadSubmitResult> | null
  setSubmitLeadResultData: (resultData: Partial<LeadSubmitResult>) => void
  valid: boolean
  setValid: (v: boolean) => void
  loading: boolean
  setLoading: (v: boolean) => void
}

const initialState: ILeadContext = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  submitLead: (_: any) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    return new Promise<void>(() => {})
  },

  submitLeadResultData: null, //change it with null
  invalidZipCodeError: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
  invalidEmailError: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setSubmitLeadResultData: (_: Partial<LeadSubmitResult> | null) => {},
  leadFormStaticData: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setLeadFormStaticData: (_: LeadFormStaticData) => {},

  valid: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setValid: (_: boolean) => {},

  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setLoading: (_: boolean) => {}
}

const LeadContext = React.createContext<ILeadContext>(initialState)

const leadProvider = memo((props: any) => {
  const [state, setState] = useState({
    ...initialState,
    reset: () => {
      setState((state) => ({
        ...state,
        leadFormStaticData: null,
        submitLeadResultData: null
      }))
    },
    setValid: (v: boolean) => {
      setState((state) => ({
        ...state,
        valid: v
      }))
    },
    setLoading: (v: boolean) => {
      setState((state) => ({
        ...state,
        loading: v
      }))
    },
    setLeadFormStaticData: (leadFormStaticData: LeadFormStaticData) => {
      setState((state) => ({
        ...state,
        leadFormStaticData
      }))
    },
    submitLead: async (leadData: any): Promise<void> => {
      //TODO tato declare type
      if (state.loading) {
        return
      }
      state.setLoading(true)
      setState((state) => ({
        ...state,
        invalidZipCodeError: '',
        invalidEmailError: ''
      }))
      try {
        const { data } = await apolloClient.mutate({
          mutation: CREATE_LEAD_MUTATION,
          variables: {
            input: leadData
          }
        })
        setState((state) => ({
          ...state,
          submitLeadResultData: data.createLead
        }))
        state.setLoading(false)
      } catch (err: any) {
        state.setLoading(false)

        const error = err.graphQLErrors ? err.graphQLErrors[0] : null

        if (error && error.extensions && error.extensions.invalidArgs) {
          const errType = error.extensions.invalidArgs[0]
          if (errType === 'validEmail') {
            setState((state) => ({
              ...state,
              invalidEmailError: error.message
            }))
          } else if (errType === 'locationData.zipCode') {
            setState((state) => ({
              ...state,
              invalidZipCodeError: error.message
            }))
          } else {
            throw err
          }
        } else {
          throw err
        }
      }
    }
  })

  return (
    <LeadContext.Provider value={state}>{props.children}</LeadContext.Provider>
  )
})

leadProvider.displayName = 'leadProvider'

export const LeadProvider = leadProvider

export default LeadContext
