import { gql } from '@apollo/client'

export const GET_VOCABULARY_QUERY = gql`
  query ($key: String!) {
    getVocabularyContentByKey(key: $key) {
      items {
        _id
        title
      }
    }
  }
`
