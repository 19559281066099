import Image from 'next/legacy/image'
import Logo from '../../public/assets/logo.webp'
import classes from './Header.module.scss'
import Link from 'next/link'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import ContactUs from '../modals/ContactUs'
import { AiOutlineMenu } from 'react-icons/ai'
import cn from 'clsx'
import AddCompany from '../modals/AddCompany'
import LeadAcquisitionModal from '../modals/LeadAcquisitionModal'
import ClickOutsideWrapper from '../ui/ClickOutsideWrapper'
import { useRouter } from 'next/router'
import LeadContext from '../../contexts/LeadContext'
import NavLink, { navLinkClass } from '../ui/NavLink'

export default function Header() {
  const [showContactUs, setShowContactUs] = useState(false)
  const [showAddCompany, setShowAddCompany] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLeadAcquisition, setShowLeadAcquisition] = useState(false)
  const humburgerMenuRef = useRef<HTMLButtonElement>(null)

  const { pathname } = useRouter()
  const { setLeadFormStaticData } = useContext(LeadContext)

  const showLinks = pathname?.indexOf('/apply/') !== -1

  const onApply = useCallback(() => {
    // just for additional insurance
    setLeadFormStaticData({})
    setShowLeadAcquisition(true)
  }, [setLeadFormStaticData, setShowLeadAcquisition])
  const rightSideNav = useMemo(
    () => (
      <>
        <div className="flex items-center text-[12px] font-bold font-arial-black max-[1250px]:flex-1 justify-center">
          <ClickOutsideWrapper
            excludeCompRef={humburgerMenuRef}
            componentVisible={showMobileMenu}
            onClickOutside={() => {
              setShowMobileMenu(false)
            }}
            className={cn('max-[1250px]:hidden ', {
              [classes.menuContainer]: showMobileMenu
            })}>
            <NavLink
              href={'/'}
              name={'HOME'}
              onClick={() => setShowMobileMenu(false)}
            />

            <NavLink
              href={'/truck-driving-jobs'}
              name={'TRUCK DRIVING JOBS'}
              onClick={() => setShowMobileMenu(false)}
            />

            <NavLink
              href={'/trucking-companies'}
              name={'TRUCKING COMPANIES'}
              onClick={() => setShowMobileMenu(false)}
            />

            <NavLink
              href={'/truck-drivers-news'}
              name={'TRUCK DRIVER NEWS'}
              onClick={() => setShowMobileMenu(false)}
            />

            <span
              className={`${navLinkClass} cursor-pointer`}
              onClick={() => setShowAddCompany(true)}>
              ADD YOUR COMPANY
            </span>
            <span
              className={`${navLinkClass} cursor-pointer mr-4`}
              onClick={() => setShowContactUs(true)}>
              CONTACT US
            </span>
          </ClickOutsideWrapper>
          <div className="  text-center">
            <h1 className="max-[374px]:text-[14px] max-[316px]:hidden min-[769px]:hidden max-[768px]:text-white text-[18px] uppercase">
              driversjobchoice.com
            </h1>

            <button
              className={`${classes.navRedButton} max-[486px]:hidden`}
              onClick={() => onApply()}>
              CLICK TO APPLY TO MULTIPLE COMPANIES
            </button>
          </div>
        </div>
        <button
          ref={humburgerMenuRef}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setShowMobileMenu(!showMobileMenu)
          }}
          className=" hidden max-[1250px]:block border border-solid border-[#ffffff1a] p-[0.4rem]"
          type="button">
          <AiOutlineMenu className="text-[20px] text-white w-[1.5em] h-[1.5em] " />
        </button>
      </>
    ),
    [showMobileMenu, onApply]
  )
  return (
    <>
      <nav
        className={`z-[11] w-full sticky top-0 bg-black flex flex-wrap items-center justify-between px-4	py-2 ${classes.navBar}`}>
        <Link className={classes.logoWrapper} href="/">
          <Image
            layout="fill"
            objectFit="contain"
            priority={true}
            className={classes.logo}
            src={Logo}
            sizes="38px"
            alt="DJC Logo"
          />
        </Link>
        {!showLinks ? (
          rightSideNav
        ) : (
          <h1 className="uppercase text-[#fff] text-[18px] font-arial-black">
            driversjobchoice.com
          </h1>
        )}
        {showLinks ? null : (
          <button
            className={`${classes.navRedButtonMobile} min-[487px]:hidden max-[486px]:block w-full mt-[10px] max-[418px]:text-xs max-[350px]: text-[10px] px-[2px]`}
            onClick={() => onApply()}>
            CLICK TO APPLY TO MULTIPLE COMPANIES
          </button>
        )}
      </nav>
      {showContactUs && (
        <ContactUs open={showContactUs} handleClose={setShowContactUs} />
      )}

      {showAddCompany && (
        <AddCompany open={showAddCompany} handleClose={setShowAddCompany} />
      )}
      {showLeadAcquisition && (
        <LeadAcquisitionModal
          open={showLeadAcquisition}
          handleClose={setShowLeadAcquisition}
        />
      )}
    </>
  )
}
