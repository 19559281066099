import S3ContentLoader from '../s3ContentLoader'
import CustomizedModal from './CustomizedModal'

interface Props {
  open: boolean
  handleClose: (v: boolean) => void
}

export default function PrivacyPolicy({ open, handleClose }: Props) {
  return (
    <CustomizedModal
      open={open}
      footer={
        <div className="flex justify-end">
          <button
            onClick={() => handleClose(false)}
            type="button"
            className="mt-2 text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">
            <div className="flex items-center content-center justify-center text-[16px] font-bold font-arial">
              <span>Close</span>
            </div>
          </button>
        </div>
      }
      onCancel={() => handleClose(false)}>
      <S3ContentLoader
        bucket="dialogs"
        defaultS3FileName="privacy-policy-terms.html"
        s3FileName="privacy-policy-terms"
      />
    </CustomizedModal>
  )
}
