export const GA_TRACKING_ID = 'AW-440088795'

type GtagFunction = (
  arg1: string,
  arg2: string,
  arg3: { send_to: string }
) => void

declare let gtag: GtagFunction

export const sendGAConversionEvent = () => {
  gtag('event', 'conversion', {
    send_to: 'AW-440088795/IHXkCMTThv0CENvx7NEB'
  })
}
