import apolloClient from '../../apollo-client'

import {
  ReactNode,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'

import { GET_LEADFORM_QUERY } from '../../graphql/leadForm'
import { DynamicFieldType, LeadFormField } from '../../types/LeadForm'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import * as yup from 'yup'
import {
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  useMediaQuery
} from '@mui/material'
import MultiSelect from '../ui/input/MultiSelect'
import SingleSelect from '../ui/input/SingleSelect'
import DOBField from '../ui/input/DOBField'
import React from 'react'
import PrivacyPolicy from '../modals/PrivacyPolicy'
import RecognitionAward from '../modals/RecognitionAward'
import Grid from '@mui/material/Grid' // Grid version 2
import dayjs from 'dayjs'
import LeadContext from '../../contexts/LeadContext'
import { LoadMask } from '../ui/LoadMask'
import GlobalContext from '../../contexts/GlobalContext'

export type FormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  locationData: {
    zipCode: string
  }
  dob: dayjs.Dayjs
  dynamicFormValues: {
    [key: string]: any
  }
  dobData: {
    month: { id: string; label: string } | null
    day: string | null
    year: string | null
  }
}

const initialSchema = yup
  .object({
    firstName: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name ').required(),
    email: yup.string().label('Email').required().email(),

    phone: yup
      .string()
      .label('Phone Number')
      .required()
      .matches(
        new RegExp(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/),
        'Please enter a valid 10 digit phone number'
      ),
    locationData: yup.object().shape({
      zipCode: yup
        .string()
        .label('Zip Code')
        .required()
        .matches(new RegExp(/^\d{5}(?:[-\s]\d{4})?$/), 'Not a valid zip code!')
    }),
    dobData: yup.object().shape({
      month: yup
        .object()
        .shape({
          label: yup.string().label('Month').required().nullable(),
          id: yup.string().label('Month').required().nullable()
        })
        .label('Month')
        .required()
        .nullable(),
      day: yup.string().label('Day').required().nullable(),
      year: yup.string().label('Year').required().nullable()
    })
  })
  .required()

const ZIP_CODE_FIELD_KEY = 'locationData.zipCode'

export interface LeadFormRefType {
  getFormValues: () => void
  isValidForm: () => boolean
}

interface Props {
  submitBtn?: ReactNode
  leadFormData?: any
}
function LeadForm(
  { submitBtn = null, leadFormData }: Props,
  ref: Ref<LeadFormRefType>
) {
  const [showSuccess] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [showRecognitionAward, setShowRecognitionAward] = useState(false)
  const [fields, setFields] = useState<LeadFormField[]>([])

  const { setErrorMsg } = useContext(GlobalContext)

  const [schema, setSchema] = useState(initialSchema)
  const [formId, setFormId] = useState<string>()

  const [loading, setLoading] = useState(false)

  const smallScreen = useMediaQuery('(max-width:768px)')

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setError,
    register,
    trigger,
    setValue
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      locationData: { zipCode: '' },
      dobData: {
        day: null,
        month: null,
        year: null
      }
    }
  })

  const {
    invalidZipCodeError,
    invalidEmailError,
    leadFormStaticData,
    setValid
  } = useContext(LeadContext)

  useEffect(() => {
    setValid(isValid)
  }, [isValid, setValid])

  useEffect(() => {
    if (invalidZipCodeError) {
      setError(
        ZIP_CODE_FIELD_KEY,
        {
          type: 'custom',
          message: invalidZipCodeError
        },
        {
          shouldFocus: true
        }
      )
    }
  }, [setError, invalidZipCodeError])


  
  useEffect(() => {
    if (invalidEmailError) {
      setError(
        'email',
        {
          type: 'custom',
          message: invalidEmailError
        },
        {
          shouldFocus: true
        }
      )
    }
  }, [setError, invalidEmailError])
  const getDynamicFormValues = (values: { [key: string]: any }): object[] => {
    const filledFields = fields.filter((f) => {
      const val = values[f._id]
      return val && (!Array.isArray(val) || !!val.length)
    })

    return filledFields.map((f) => {
      let textValue: string[] | null = []

      if (f.type === DynamicFieldType.SELECT) {
        const map: any = {}

        for (const { _id, title } of f.options) {
          map[_id] = title
        }

        if (Array.isArray(values[f._id]) === false) {
          textValue.push(map[values[f._id] as string])
        } else {
          for (const val of values[f._id] as string[]) {
            textValue.push(map[val])
          }
        }
      } else {
        textValue = null
      }

      return {
        value: values[f._id],
        dynamicCfgId: f._id,
        searchKey: f['searchKey'],
        textValue: textValue
      }
    })
  }

  const getFormValues = () => {
    const { dobData, ...values } = getValues()

    const dob = dayjs()
      .year(+dobData.year!)
      .date(+dobData.day!)
      .month(dobData.month ? +dobData.month.id! : 0)
      .format('YYYY-MM-DD')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { clientName: _, ...staticData } = leadFormStaticData || {}
    const leadData = {
      ...values,
      formId: formId,
      documentReferrer: document.referrer,
      dob,
      dynamicFormValues: getDynamicFormValues(values.dynamicFormValues),
      ...staticData
    }

    delete leadData.heroImage

    return leadData
  }

  const isValidForm = () => {
    return isValid
  }

  useImperativeHandle(ref, () => ({ getFormValues, isValidForm }))

  const Label = (text: string) => {
    return <label className="text-[#0000008a]">{text}</label>
  }

  const loadLeadForm = useCallback(async () => {
    if (leadFormData) {
      return leadFormData
    }
    const variables: any = {}
    if (leadFormStaticData?.campaignId) {
      variables.campaignId = leadFormStaticData.campaignId
    }
    try {
      const { data } = await apolloClient.query({
        query: GET_LEADFORM_QUERY,
        variables
      })

      return data.leadForms
    } catch (e) {
      setErrorMsg('Uh-oh! Error loading form. Try again soon.')
    }
  }, [setErrorMsg, leadFormStaticData, leadFormData])

  useEffect(() => {
    const load = async () => {
      setLoading(true)

      const leadForm = (await loadLeadForm())?.[0]

      if (!leadForm) {
        setLoading(false)
        return
      }

      const fields: LeadFormField[] = leadForm.fields

      setFormId(leadForm._id)

      setFields(fields)

      const validations: any = {}
      fields.map((f) => {
        if (f.required) {
          if (f.type === DynamicFieldType.SELECT) {
            if (f.config.multi) {
              validations[f._id] = yup.array().min(1).label(f.label).required()
            } else {
              validations[f._id] = yup.string().label(f.label).required()
            }
          }
        }
      })

      if (Object.keys(validations).length) {
        const dynamicSchema = yup.object({
          dynamicFormValues: yup.object().shape(validations)
        })

        setSchema(initialSchema.concat(dynamicSchema))
      }

      setLoading(false)
    }

    load()
  }, [loadLeadForm])

  return (
    <>
      <LoadMask active={loading}></LoadMask>
      <Divider className="font-bold my-[1rem] mx-[0px]">
        Personal Information
      </Divider>

      {!showSuccess ? (
        <Grid
          container
          component="form"
          rowSpacing={2}
          columnSpacing={3}
          noValidate
          autoComplete="off">
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('First Name')}
                  error={!!errors.firstName}
                  helperText={errors?.firstName?.message || ' '}
                  fullWidth={true}
                  variant="standard"
                />
              )}></Controller>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('Last Name')}
                  error={!!errors.lastName}
                  helperText={errors?.lastName?.message || ' '}
                  fullWidth={true}
                  variant="standard"
                />
              )}></Controller>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('Email')}
                  error={!!errors.email}
                  helperText={errors?.email?.message || ' '}
                  fullWidth={true}
                  ref={register('email').ref}
                  variant="standard"
                />
              )}></Controller>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('Phone')}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message || ' '}
                  fullWidth={true}
                  variant="standard"
                />
              )}></Controller>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div>Date Of birth</div>
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* <div>Date Of birth</div> */}
            <DOBField
              trigger={trigger}
              control={control}
              setValue={setValue}
              errors={errors}></DOBField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={ZIP_CODE_FIELD_KEY}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={Label('Zip Code')}
                  error={!!errors.locationData?.zipCode}
                  helperText={errors?.locationData?.zipCode?.message || ' '}
                  fullWidth={true}
                  ref={register(ZIP_CODE_FIELD_KEY).ref}
                  variant="standard"
                />
              )}></Controller>
          </Grid>

          {fields.map((f, key) => {
            if (f.type === DynamicFieldType.DIVIDER) {
              return (
                <Grid key={key} item xs={12}>
                  <Divider className="font-bold my-[1rem] mx-[0px]">
                    {f.label}
                  </Divider>
                </Grid>
              )
            }

            if (
              smallScreen &&
              f.type === DynamicFieldType.SELECT &&
              f.config.multi
            ) {
              return (
                <Grid item xs={12} sm={f.size || 6} key={f._id}>
                  <Controller
                    control={control}
                    key={f._id}
                    defaultValue={[]}
                    name={`dynamicFormValues.${f._id}`}
                    render={({ field }) => (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">{f.label}</FormLabel>
                        <FormGroup sx={{ ml: 3 }}>
                          {f.options.map((option) => (
                            <FormControlLabel
                              key={option._id}
                              control={
                                <Checkbox
                                  {...field}
                                  checked={
                                    field.value &&
                                    !!field.value.find(
                                      (v: string) => v == option._id
                                    )
                                  }
                                  onChange={(value) => {
                                    let val = field.value || []

                                    if (value.target.checked) {
                                      val.push(option._id)
                                    } else {
                                      val = val.filter(
                                        (v: string) => v !== option._id
                                      )
                                    }
                                    field.onChange(val)
                                  }}
                                  name={option._id}
                                />
                              }
                              label={option.title}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    )}></Controller>
                </Grid>
              )
            }

            if (
              !smallScreen &&
              f.type === DynamicFieldType.SELECT &&
              f.config.multi
            ) {
              return (
                <Grid item xs={12} sm={f.size || 6} key={f._id}>
                  <MultiSelect
                    required={f.required}
                    control={control}
                    error={!!errors.dynamicFormValues?.[f._id]}
                    controlName={`dynamicFormValues.${f._id}`}
                    placeholder="Please select"
                    label={f.label}
                    fieldNames={{
                      fieldLabel: 'title',
                      fieldValue: '_id'
                    }}
                    staticData={f.options}
                  />
                </Grid>
              )
            }

            if (f.type === DynamicFieldType.SELECT && !f.config.multi) {
              return (
                <Grid item xs={12} sm={f.size || 6} key={f._id}>
                  <SingleSelect
                    key={f._id}
                    required={f.required}
                    control={control}
                    error={!!errors.dynamicFormValues?.[f._id]}
                    controlName={`dynamicFormValues.${f._id}`}
                    label={f.label}
                    fieldNames={{
                      fieldLabel: 'title',
                      fieldValue: '_id'
                    }}
                    staticData={f.options}
                  />
                </Grid>
              )
            }

            if (f.type === DynamicFieldType.TOGGLE) {
              return (
                <Grid item xs={12} sm={f.size || 6} key={f._id}>
                  <Controller
                    control={control}
                    key={key}
                    name={`dynamicFormValues.${f._id}`}
                    render={({ field }) => (
                      <FormControlLabel
                        label={Label(f.label)}
                        control={<Switch {...field} />}
                      />
                    )}></Controller>
                </Grid>
              )
            }
          })}

          {submitBtn && (
            <Grid item xs={12}>
              {submitBtn}
            </Grid>
          )}

          <Grid className="py-[0] mt-[15px]" item xs={12}>
            <div className="border-none text-black bg-[#fdf3cd] mb-[1rem]">
              <div className="p-[.75rem]">
                <p className="text-[12px] color-[#777777] leading-[1.2rem] text-start	">
                  By submitting this form, I agree with the
                  <span
                    className="text-[#00f] underline cursor-pointer"
                    onClick={() => setShowPrivacyPolicy(true)}>
                    <b> terms, conditions and use of information </b>
                  </span>
                  I am providing, and I give permission to obtain consumer
                  reports about me from HireRight/DAC as well as obtaining
                  information about my driving record from the FMCSA and/or the
                  Pre-employment Screening Program (PSP). I understand that the
                  calls, voicemails, and text messages may be transmitted using
                  an auto dialing system or prerecorded voice.
                </p>
              </div>
            </div>
            <div className="border-none text-black bg-[#d4edda]">
              <div className="p-[.75rem]">
                <p className="text-[12px] color-[#777777] leading-[1.2rem] text-start">
                  $250.00 Driver Recognition Award: Truck drivers are the
                  backbone of America and we want to recognize drivers for the
                  sacrifices they make to {`"`}Keep America Moving!{`"`} By
                  submitting your application, you are automatically enrolled in
                  Driver Job Choice{`'`}s weekly Driver Recognition program
                  <span
                    className="text-[#00f] underline cursor-pointer"
                    onClick={() => setShowRecognitionAward(true)}>
                    <b>(subject to giveaway rules)</b>
                  </span>
                  . Each week we will recognize a driver and send them a check
                  for $250 cash! Winners announced weekly through our social
                  media accounts. Follow us today!
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className="text-center text-[16px] text-[#6f6f6f] font-lato-light font-semibold">
          Thank you for your interest. A representative of driversjobchoice.com
          will contact you shortly.
        </div>
      )}
      <PrivacyPolicy
        open={showPrivacyPolicy}
        handleClose={setShowPrivacyPolicy}
      />
      <RecognitionAward
        open={showRecognitionAward}
        handleClose={setShowRecognitionAward}
      />
    </>
  )
}

export default React.forwardRef(LeadForm)
