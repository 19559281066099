import React, { ReactNode, useCallback, useEffect, useRef } from 'react'

interface Props {
  componentVisible: boolean
  children: ReactNode
  onClickOutside: () => void
  className?: string
  excludeCompRef?: React.RefObject<HTMLButtonElement>
}

const ClickOutsideWrapper = ({
  componentVisible,
  children,
  className = '',
  onClickOutside,
  excludeCompRef
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        wrapperRef &&
        componentVisible &&
        !wrapperRef.current?.contains(event.target as HTMLElement) &&
        !excludeCompRef?.current?.contains(event.target as HTMLElement)
      ) {
        onClickOutside()
      }
    },
    [wrapperRef, onClickOutside, excludeCompRef, componentVisible]
  )

  useEffect(() => {
    if (componentVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [componentVisible, handleClickOutside])

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  )
}

export default ClickOutsideWrapper
