import Link from 'next/link'
import { useRouter } from 'next/router'

type Props = {
  href: string
  name: string
  onClick?: () => void
}

export default function NavLink({ href, name, onClick }: Props) {
  const router = useRouter()
  const { pathname } = router
  const activeLink =
    navLinkClass +
    ' underline underline-offset-[1.1rem] decoration-2 max-[1250px]:underline-offset-[0.5rem]'
  const getClassName = () => {
    if (pathname === '/' && href === '/') {
      return activeLink
    } else if (href == '/') {
      return navLinkClass
    } else {
      return pathname.includes(href) ? activeLink : navLinkClass
    }
  }
  return (
    <Link
      className={getClassName()}
      href={href}
      onClick={() => {
        onClick?.()
      }}>
      {name}
    </Link>
  )
}

export const navLinkClass =
  'text-[#fff] hover:text-gray-300 px-[0.5rem] py-[0.5rem]'
