import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  active: boolean
  className?: string
}
export const LoadMask = ({ active = false, className = 'fixed' }: Props) => {
  if (!active) {
    return null
  }
  return (
    <Box
      className={`${className} z-50	 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
      <CircularProgress size={50} thickness={4} />
    </Box>
  )
}
