import Link from 'next/link'
import {
  GrFacebook,
  GrYoutube,
  GrTwitter,
  GrInstagram,
  GrPinterest,
  GrLinkedin
} from 'react-icons/gr'
import classes from './Socials.module.scss'

export default function SocialMediaLinks() {
  return (
    <div className="bg-[#323139] flex justify-end py-2 px-2 max-[768px]:justify-center">
      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="youtube"
        href="https://www.youtube.com/channel/UCgv4jfNUuVj7r3Q7S6UoLDg">
        <GrYoutube className={`${classes.social} fill-[#FFF] bg-[#FF0000]`} />
      </Link>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter"
        href="https://twitter.com/driverjobchoice">
        <GrTwitter className={`${classes.social} fill-[#FFF] bg-[#04aced]`} />
      </Link>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook"
        href="https://www.facebook.com/driversjobchoice">
        <GrFacebook className={`${classes.social}  fill-white bg-[#3b5999]`} />
      </Link>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="instagram"
        href="https://www.instagram.com/driversjobchoice/">
        <GrInstagram className={`${classes.social} fill-[#FFF] bg-[#cc764f]`} />
      </Link>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="pinterest"
        href="https://www.pinterest.com/driverjobchoice/">
        <GrPinterest className={`${classes.social} fill-[#FFF] bg-[#cd1f28]`} />
      </Link>

      <Link
        target="_blank"
        rel="noopener noreferrer"
        aria-label="linkedin"
        href="https://www.linkedin.com/company/drivers-job-choice">
        <GrLinkedin className={`${classes.social} fill-white bg-[#007db7]`} />
      </Link>
    </div>
  )
}
