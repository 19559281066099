import { ReactNode, useContext, useEffect, useRef } from 'react'
import Footer from './footer/Footer'
import Header from './header/Header'
import { usePathname } from 'next/navigation'
import { Snackbar } from '@mui/material'
import GlobalContext from '../contexts/GlobalContext'

interface Props {
  children: ReactNode
}
export default function Layout({ children }: Props) {
  const pathname = usePathname()
  const scrollableRef = useRef<HTMLDivElement>(null)

  const { errorMsg, setErrorMsg } = useContext(GlobalContext)

  useEffect(() => {
    scrollableRef.current?.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }, [pathname])
  return (
    <div
      ref={scrollableRef}
      id="scrollableTarget"
      className="flex flex-col h-screen overflow-auto ">
      <Header />
      <main className="flex-grow">{children}</main>

      <Footer />
      <div id="overlay-container"></div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!errorMsg}
        onClose={() => setErrorMsg('')}
        message={errorMsg}
        key="error-snackbar"
        ContentProps={{
          classes: {
            root: 'bg-zinc-50 z-50',
            message: 'text-[#444]'
          }
        }}
        autoHideDuration={4000}
      />
    </div>
  )
}
