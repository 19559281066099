import { MdSend } from 'react-icons/md'

import { useContext, useEffect, useRef, useState } from 'react'

import CustomizedModal from './CustomizedModal'

import LeadForm, { LeadFormRefType } from '../lead-form/LeadForm'
import styled from 'styled-components'
import LeadContext from '../../contexts/LeadContext'
import LeadSubmitConfirmation from '../lead-form/LeadSubmitConfirmation'

import HeroImage from '../ui/image/HeroImage'
import GlobalContext from '../../contexts/GlobalContext'

interface Props {
  open: boolean
  handleClose: (v: boolean) => void
}

export default function LeadAcquisitionModal({ open, handleClose }: Props) {
  const leadFormRef = useRef<LeadFormRefType>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    submitLead,
    submitLeadResultData,
    reset,
    leadFormStaticData,
    loading,
    valid
  } = useContext(LeadContext)

  const { loadScripts, setErrorMsg } = useContext(GlobalContext)

  useEffect(() => {
    loadScripts('jobApplyStart')
  }, [loadScripts])

  const onClose = (v: boolean) => {
    reset()
    handleClose(v)
  }

  if (submitLeadResultData) {
    return (
      <CustomizedModal
        classname={''}
        loadMask={loading}
        hideFooter={true}
        open={true}
        onCancel={() => onClose(false)}>
        <LeadSubmitConfirmation onLinkClick={() => onClose(false)} />
      </CustomizedModal>
    )
  }

  const onSubmit = async () => {
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)
    const form = leadFormRef.current
    if (form && form.isValidForm()) {
      const values = leadFormRef.current.getFormValues()
      try {
        await submitLead(values)
      } catch (e) {
        setErrorMsg(
          'Uh-oh! Error adding truck driver job application. Try again soon.'
        )
      }
    }
    setIsSubmitting(false)
  }

  return (
    <CustomizedModal
      classname={''}
      loadMask={loading}
      contentCls={
        leadFormStaticData?.clientName ? '!pt-0 relative' : 'relative'
      }
      footer={[
        <button
          key="submit"
          type="button"
          disabled={!valid || isSubmitting}
          className="disabled:bg-[#888] w-full text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out"
          onClick={onSubmit}>
          <div className="flex items-center content-center justify-center text-[16px] font-bold font-arial">
            <span>Submit</span>
            <MdSend className="ml-2" />
          </div>
        </button>
      ]}
      open={open}
      onCancel={() => onClose(false)}>
      <>
        {leadFormStaticData?.clientName ? (
          <>
            <HeroImage
              srcUrl={leadFormStaticData.heroImage}
              alt={`${leadFormStaticData.clientName} image`}
            />
            <h2 className="font-arial-black text-[2rem] m-[10px_0px]">
              {leadFormStaticData?.clientName}
            </h2>
            <h4 className="font-lato-light text-[#6f6f6f] text-[16px]">
              Complete the short-form below and a company representative will be
              in contact with you as soon as possible.
            </h4>
          </>
        ) : (
          <>
            <H1>YOUR LIFE, YOUR CHOICE!</H1>
            <h4>
              Complete the short-form below to put our service to work for you.
              We
              {`'`}ll match your criteria to the best companies in the industry
              right now and you can choose the right truck driver job for you!
            </h4>
          </>
        )}
        <LeadForm ref={leadFormRef}></LeadForm>
      </>
    </CustomizedModal>
  )
}

const H1 = styled.h1`
  color: #444;
  font-size: 26px;
  font-family: Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  display: block;
`
