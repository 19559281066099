import { KeyValPair } from './Common'

export default interface LeadForm {
  _id: string
  fields: LeadFormField[]
}

export interface LeadFormFieldOptions extends KeyValPair {
  _id: string
  title: string
}
export interface LeadFormField {
  _id: string
  label: string
  type: DynamicFieldType
  required: boolean
  size: number
  searchKey: string
  groupId: string
  order: number
  config: any
  options: LeadFormFieldOptions[]
}
export enum DynamicFieldType {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX',
  TOGGLE = 'TOGGLE',
  DATE_PICKER = 'DATE_PICKER',
  SELECT = 'SELECT',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  RICH_TEXT = 'RICH_TEXT',
  CHIPS = 'CHIPS',
  UPLOAD = 'UPLOAD',
  RADIO = 'RADIO',
  IMAGE = 'IMAGE',
  DIVIDER = 'DIVIDER'
}

export interface IConfirmationText {
  failed: string
  success: string
}

export interface LeadSubmitResult {
  leadId: string
  isGenericLead: boolean
  client?: {
    confirmationText?: IConfirmationText
  }
  qualifiedCliens: {
    client: {
      name: string
    }
    matchedCampaign: {
      title: string
      trackingNumbers: {
        routingNumber: string
        trackingNumber: string // TODO dont load one of them detect
      }[]
    }
  }[]
}

export interface LeadFormStaticData {
  clientId?: string
  campaignId?: string
  jobPostId?: string
  clientName?: string
  heroImage?: string
}
