import fetch from 'cross-fetch'
import DOMPurify from 'dompurify'
import { useCallback, useEffect, useState } from 'react'
import { SkeletonCard } from './ui/SkeletonCard'

const S3_BUCKET_URL = process.env.NEXT_PUBLIC_S3_BUCKET_URL

export interface S3ContentLoaderProps {
  bucket: string
  defaultS3FileName?: string
  s3FileName?: string
  className?: string
}

export default function S3ContentLoader({
  bucket,
  defaultS3FileName,
  s3FileName,
  className = ''
}: S3ContentLoaderProps) {
  const [content, setContent] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const dowloadContent = useCallback(
    async (s3FileName?: string) => {
      setLoading(true)
      const loadContent = async (url: string): Promise<boolean> => {
        const res = await fetch(url)

        if (!res.ok) {
          return false
        }
        const text = await res.text()

        if (text && !text.includes('<meta charset="utf-8">')) {
          setContent(DOMPurify.sanitize(text))
        }

        return true
      }

      try {
        const baseFileUrl = `${S3_BUCKET_URL}/${bucket}`

        let success = false
        if (s3FileName) {
          success = await loadContent(`${baseFileUrl}/${s3FileName}.html`)
        }

        if (!success) {
          success = await loadContent(
            `${baseFileUrl}/${defaultS3FileName}.html`
          )
        }

        if (!success) {
          setContent('N/A')
        }
      } catch (e) {
        setContent('N/A')
      }
      setLoading(false)
    },
    [bucket, defaultS3FileName]
  )

  useEffect(() => {
    dowloadContent(s3FileName)
  }, [s3FileName, dowloadContent])

  return (
    <>
      {loading ? (
        <SkeletonCard className={className} />
      ) : (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: content
          }}></div>
      )}
    </>
  )
}
