import { STATES } from '../../constants/States'
import styled from 'styled-components'
import Link from 'next/link'

interface IStateData {
  display: string
  urlPart: string
}

const STATE_PREFIX = 'Truck Driver Jobs in'
const stateData: IStateData[] = Object.values(STATES).map((stateName) => {
  const display = `${STATE_PREFIX} ${stateName}`
  return {
    display,
    urlPart: display
      .replace(new RegExp('Truck Driver Jobs in', 'g'), 'truck-driving-jobs-in')
      .replace(new RegExp(' ', 'g'), '-')
      .toLowerCase()
  }
})
const Wrapper = styled.section``

export default function StateSection() {
  const getStateLink = (state: IStateData, index: number) => {
    return (
      <Link
        className="text-[11px] text-[#333] hover:underline"
        key={index}
        href={`/${state.urlPart}`}>
        {state.display}
      </Link>
    )
  }
  return (
    <Wrapper className="grid grid-cols-2 2xl:grid-cols-8 xl:grid-cols-7 gap-1  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 bg-[#f3f4f6] px-10 py-5">
      {stateData.map((state, index) => getStateLink(state, index))}
    </Wrapper>
  )
}
