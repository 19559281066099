import { gql } from '@apollo/client'

export const SEND_EMAIL_MUTATION = gql`
  mutation sendEmail(
    $to: String!
    $from: String!
    $subject: String!
    $body: String!
  ) {
    sendEmail(to: $to, from: $from, subject: $subject, body: $body)
  }
`
