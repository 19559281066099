import Link from 'next/link'
import { ReactNode, useContext, useEffect } from 'react'
import LeadContext from '../../contexts/LeadContext'
import S3ContentLoader from '../s3ContentLoader'
import cn from 'clsx'
import GlobalContext from '../../contexts/GlobalContext'
import { sendGAConversionEvent } from '../../lib/ga'

interface Props {
  textCenter?: boolean
  onLinkClick?: () => void
  showCongrets?: boolean
}

export default function LeadSubmitConfirmation({
  textCenter = false,
  showCongrets = true,
  onLinkClick
}: Props) {
  const { submitLeadResultData, leadFormStaticData } = useContext(LeadContext)

  const { loadScripts, loadImgScript } = useContext(GlobalContext)

  useEffect(() => {
    loadScripts('jobApply')
    loadImgScript('jobs2careers')
    sendGAConversionEvent()
  }, [loadImgScript, loadScripts])

  const getCompanyList = (
    rows = true,
    prefix: ReactNode = null,
    sufix: ReactNode = null
  ) => {
    return submitLeadResultData?.qualifiedCliens?.map((qc, index) => {
      const numbers = qc?.matchedCampaign?.trackingNumbers[0]
      const number = numbers?.trackingNumber || numbers?.routingNumber

      let link = null
      if (!number) {
        if (!rows) {
          return null
        }
        link = <div className="font-bold ">{qc.client.name}</div>
      } else {
        link = (
          <>
            {prefix}
            Call{' '}
            <span className="font-bold ">
              {qc.client.name} - {` `}{' '}
            </span>
            <Link
              key={index}
              className="text-[#00f] underline"
              href={`tel:${number}`}>
              {number}
            </Link>
            {sufix}
          </>
        )
      }
      return rows ? (
        <div key={`${index}-div`}>
          {prefix} {link} {sufix}
        </div>
      ) : (
        link
      )
    })
  }

  const confirmationText = submitLeadResultData?.client?.confirmationText

  if (!submitLeadResultData?.isGenericLead && confirmationText) {
    const isSuccess = submitLeadResultData.qualifiedCliens?.length === 1

    let msg
    if (!isSuccess && confirmationText.failed) {
      msg = confirmationText.failed
    } else if (isSuccess && confirmationText.success) {
      msg = confirmationText.success
    }

    if (msg) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: msg
          }}></div>
      )
    }
  }

  const congrets = (
    <h1 className="text-[#000000de] text-[20px] leading-[32px] font-[500]">
      CONGRATULATIONS!
    </h1>
  )

  if (
    !submitLeadResultData ||
    !submitLeadResultData.qualifiedCliens ||
    submitLeadResultData.qualifiedCliens.length === 0
  ) {
    return (
      <p
        className={cn('font-lato-light text-[#444]', {
          'text-center': textCenter
        })}>
        Thank you for submitting your information through DriversJobChoice.com.
        We are sorry to inform you that your criteria did not meet the
        requirements of our companies. You can continue your search for truck
        driving jobs here: {` `}
        <Link
          className="text-[#00f] underline cursor-pointer"
          onClick={() => onLinkClick && onLinkClick()}
          href="/truck-driving-jobs">
          https://driversjobchoice.com/truck-driving-jobs
        </Link>
      </p>
    )
  } else if (submitLeadResultData.isGenericLead) {
    return (
      <>
        {showCongrets && congrets}
        <S3ContentLoader
          bucket="confirm/content"
          defaultS3FileName="default"
          s3FileName="default"
          className="text-[#444] font-lato-light"
        />

        <div className="text-center">
          <h3 className="font-arial-black  mb-[0.5rem] text-[1.75rem] text-[#444]">
            Qualified Clients
          </h3>
          <p className="font-lato-light text-[#444]">
            Get ahead of us and reach out to a company in search of a driver
            fitting your requirements!
          </p>

          {getCompanyList()}
        </div>
      </>
    )
  } else {
    if (submitLeadResultData.qualifiedCliens.length === 1) {
      return (
        <>
          {showCongrets && congrets}

          <p className="font-lato-light text-[#444]">
            Thank you for applying to {leadFormStaticData!.clientName} ! We have
            sent your information to them and a recruiter should contact you
            soon!
            {getCompanyList(
              false,
              ` Or you can `,
              ` 
            to speak to someone now!`
            )}
          </p>
        </>
      )
    } else {
      return (
        <>
          <p>
            We are sorry to inform you that your qualifications did not meet the
            requirements of {leadFormStaticData!.clientName}. However, here are
            some companies that you qualify for:
          </p>

          {getCompanyList()}
        </>
      )
    }
  }
}
