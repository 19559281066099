import * as React from 'react'
import dayjs from 'dayjs'
import TextField from '@mui/material/TextField'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from 'react-hook-form'
import {
  Autocomplete,
  Grid,
  Snackbar,
  SnackbarContent,
  styled
} from '@mui/material'
import { FormValues } from '../../lead-form/LeadForm'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

interface Props {
  control: Control<any, any>
  errors: FieldErrors<FormValues>
  trigger: UseFormTrigger<FormValues>
  setValue: UseFormSetValue<FormValues>
}

const minDate = dayjs().subtract(18, 'year')
const minYear = minDate.year()

const maxDate = dayjs().subtract(85, 'year')
const maxYear = maxDate.year()

const ErrorSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.error.main
}))

const DOBField = ({ control, errors, trigger, setValue }: Props) => {
  const [days, setDays] = React.useState<string[]>(
    Array.from(Array(31).keys(), (_, index) => index + 1).map(String)
  )

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const month = useWatch({
    control,
    name: 'dobData.month'
  })

  const year = useWatch({
    control,
    name: 'dobData.year'
  })

  const day = useWatch({
    control,
    name: 'dobData.day'
  })

  React.useEffect(() => {
    if (!year || !month || !month.id) {
      setDays(Array.from(Array(31).keys(), (_, index) => index + 1).map(String))
      return
    }
    let d = dayjs().date(1).year(year).month(month.id)
    const newDays = Array.from(
      Array(
        dayjs(d).daysInMonth() // 31
      ).keys(),
      (_, index) => index + 1
    ).map(String)
    setDays(newDays)

    if (!newDays.includes(day)) {
      setValue('dobData.day', null)
      trigger('dobData.day')
    } else {
      d = d.date(day)
    }

    if (!d.isBetween(minDate, maxDate, 'day', '[]')) {
      setSnackbarOpen(true)
      setValue('dobData.year', null)
    }
  }, [month, year, day, setValue, trigger])

  return (
    <Grid container spacing={3} direction="row">
      <Grid item xs={12} sm={6}>
        <Controller
          name="dobData.month"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              size="medium"
              classes={{ root: 'bg-white', inputRoot: 'rounded-none' }}
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, v) => {
                field.onChange(v)
              }}
              freeSolo
              options={dayjs.Ls['en'].months!.map((v, i) => ({
                label: v,
                id: String(i)
              }))}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Month"
                  variant="standard"
                  required
                  InputProps={{
                    ...params.InputProps,
                    style: { paddingRight: '0px' },
                    endAdornment: null
                  }}
                  error={!!errors?.dobData?.month}
                  helperText={errors?.dobData?.month?.message || ' '}
                />
              )}
            />
          )}></Controller>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="dobData.day"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              size="medium"
              classes={{
                root: 'bg-white',
                inputRoot: 'rounded-none',
                paper: 'min-w-[70px]'
              }}
              onChange={(_, data) => field.onChange(data)}
              options={days}
              autoSelect={true}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Day"
                  variant="standard"
                  required
                  InputProps={{
                    ...params.InputProps,
                    style: { paddingRight: '0px' },
                    endAdornment: null
                  }}
                  error={!!errors?.dobData?.day}
                />
              )}
            />
          )}></Controller>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="dobData.year"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              freeSolo
              clearIcon={false}
              fullWidth={true}
              classes={{
                root: 'bg-white',
                inputRoot: 'rounded-none',
                paper: 'min-w-[90px]'
              }}
              onChange={(_, data) => field.onChange(data)}
              options={Array.from(
                { length: minYear - maxYear + 1 },
                (_, i) => minYear - i
              ).map(String)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Year"
                  fullWidth={true}
                  required
                  InputProps={{
                    ...params.InputProps,
                    style: { paddingRight: '0px' },
                    endAdornment: null
                  }}
                  error={!!errors?.dobData?.year}
                />
              )}
            />
          )}></Controller>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        key={'Snackbar'}>
        <ErrorSnackbarContent message="To proceed, your date of birth must indicate that you are between 18 and 85 years old." />
      </Snackbar>
    </Grid>
  )
}

DOBField.displayName = 'DOBField'

export default DOBField
