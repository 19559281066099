import classes from '../../styles/AboutUs.module.scss'
import CustomizedModal from './CustomizedModal'

interface Props {
  open: boolean
  handleClose: (v: boolean) => void
}

export default function AboutUs({ open, handleClose }: Props) {
  return (
    <CustomizedModal
      open={open}
      footer={
        <div className="flex justify-end">
          <button
            onClick={() => handleClose(false)}
            type="button"
            className="mt-2 text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-green-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out">
            <div className="flex items-center content-center justify-center text-[16px] font-bold font-arial">
              <span>Close</span>
            </div>
          </button>
        </div>
      }
      onCancel={() => handleClose(false)}>
      <div className={classes.about}>
        <h3>About Us</h3>
        <p>
          Welcome to driversjobchoice.com, your number one source for all things
          driver job-related. We{`'`}re dedicated to providing you the very best
          job-search experience, with a focus on clean, simple, and intuitive
          tools to help you find the driver job of your choice.
        </p>

        <p>
          Founded in 2021 by a group of passionate people with over 40 years
          combined experience in the driver recruiting industry. We have served
          drivers all over the US, and are thrilled to be taking a leading role
          in the driver-recruiting industry. America{`'`}s top trucking
          companies trust us to deliver top-notch service with a smile!
        </p>

        <p>
          We hope you enjoy our products as much as we have enjoyed building
          them for you. If you have any questions or comments, please don{`'`}t
          hesitate to contact us.
        </p>

        <p>Sincerely,</p>

        <p>Your Drivers Job Choice Staff</p>
      </div>
    </CustomizedModal>
  )
}
