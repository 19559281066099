import { useState } from 'react'
import SocialMediaLinks from '../home-page/soc-media/SocialMediaLinks'
import StateSection from '../home-page/StateSection'

import AboutUs from '../modals/AboutUs'
import PrivacyPolicy from '../modals/PrivacyPolicy'
import RecognitionAward from '../modals/RecognitionAward'

export default function Footer() {
  const [showAboutUs, setShowAboutUs] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [showRecognitionAward, setShowRecognitionAward] = useState(false)

  const openSitemap = () => {
    window.open('sitemap.xml', '_blank')
  }

  return (
    <footer>
      <SocialMediaLinks />

      <StateSection />

      <div className="bg-[#323139] text-center sm:text-left">
        <div className="text-[#fff] text-center p-4 text-[12px]">
          <span
            onClick={() => setShowPrivacyPolicy(true)}
            className="cursor-pointer">
            {' '}
            Privacy Policy{' '}
          </span>
          {' | '}
          <span
            onClick={() => setShowRecognitionAward(true)}
            className="cursor-pointer">
            {' '}
            Driver Recognition Award Rules{' '}
          </span>{' '}
          {' | '}
          <span onClick={() => setShowAboutUs(true)} className="cursor-pointer">
            {' '}
            About Us{' '}
          </span>{' '}
          {' | '}
          <span onClick={openSitemap} className="cursor-pointer">
            Sitemap
          </span>
          {' | '} Copyright © DriversJobChoice.com
        </div>
      </div>

      <AboutUs open={showAboutUs} handleClose={setShowAboutUs} />
      <PrivacyPolicy
        open={showPrivacyPolicy}
        handleClose={setShowPrivacyPolicy}
      />
      <RecognitionAward
        open={showRecognitionAward}
        handleClose={setShowRecognitionAward}
      />
    </footer>
  )
}
