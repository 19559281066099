import Image from 'next/legacy/image'
import DefaultHero from '../../../public/assets/img/company/default.webp'

interface Props {
  alt: string
  srcUrl?: string
  width?: number
  height?: number
}
const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63)

const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`

const HeroImage = ({ alt, srcUrl, width = 1200, height = 600 }: Props) => {
  return (
    <Image
      alt={alt}
      src={srcUrl || DefaultHero}
      layout="responsive"
      objectPosition="center"
      objectFit="cover"
      width={width}
      height={height}
      quality={90}
      priority
      sizes="(max-width: 1000px) 40vw, 100vw"
      placeholder="blur"
      blurDataURL={rgbDataURL(234, 238, 242)}
    />
  )
}

export default HeroImage
