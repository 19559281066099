import { useState } from 'react'
import apolloClient from '../apollo-client'
import { GET_VOCABULARY_QUERY } from '../graphql/vocabulary'
import { KeyValPair } from '../types/Common'

const useLoadVocabulary = (key?: string) => {
  const [data, setData] = useState<KeyValPair[]>([])
  const [error, setError] = useState<unknown>(null)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [immutableData, setImmutableData] = useState<KeyValPair[]>([])

  const load = async () => {
    try {
      setLoading(true)

      const { data } = await apolloClient.query({
        query: GET_VOCABULARY_QUERY,
        variables: {
          key
        }
      })

      const items = data.getVocabularyContentByKey.items
      setData(items)
      setImmutableData([...items])
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  return { data, error, loading, load, loaded, setData, immutableData }
}

export default useLoadVocabulary
