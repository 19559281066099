import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const API_URL = process.env.NEXT_PUBLIC_API_URL

const httpLink = createHttpLink({
  uri: API_URL
})

const headersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ['source-url']:
        typeof document !== 'undefined' ? document.location.href : null
    }
  }
})

const apolloClient = new ApolloClient({
  link: headersLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default apolloClient
