import { useEffect } from 'react'

import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import useLoadVocabulary from '../../../hooks/useLoadVocabulary'
import { KeyValPair } from '../../../types/Common'

interface Props {
  vocabularyKey?: string
  controlName: string
  control: Control<any, any>
  staticData?: KeyValPair[]
  label: string
  fieldNames?: { fieldValue?: string; fieldLabel?: string }
  error?: boolean
  required?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const SingleSelect = React.forwardRef(
  (
    {
      vocabularyKey,
      staticData,
      fieldNames: { fieldValue = '_id', fieldLabel = 'title' } = {},
      control,
      controlName,
      label,
      error,
      required
    }: Props,
    ref
  ) => {
    const {
      load,
      loaded,
      loading,
      data = [],
      setData
    } = useLoadVocabulary(vocabularyKey)

    useEffect(() => {
      if (staticData) {
        setData(staticData)
      }
    }, [staticData, setData])

    return (
      <FormControl
        error={error}
        variant="standard"
        fullWidth={true}
        required={required}>
        <InputLabel id={`${controlName}-label`}>{label}</InputLabel>
        <Controller
          name={controlName}
          defaultValue={''}
          render={({ field, fieldState: { error } }) => (
            <>
              <Select
                {...field}
                label={label}
                labelId={`${controlName}-label`}
                onChange={(event) => {
                  const value = event.target.value
                  field.onChange(value)
                }}
                ref={ref}
                fullWidth={true}
                value={field.value}
                onOpen={() => {
                  !staticData && !loaded && !loading && load()
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return
                  }

                  return data.find((d) => d[fieldValue] === selected)![
                    fieldLabel
                  ]
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP
                    }
                  }
                }}>
                {loading
                  ? [
                      <MenuItem key="loading" value="loading">
                        <ListItemText primary="Loading..." />
                      </MenuItem>
                    ]
                  : [
                      data.map((option) => (
                        <MenuItem
                          key={option[fieldValue]}
                          value={option[fieldValue]}>
                          <ListItemText primary={option[fieldLabel]} />
                        </MenuItem>
                      ))
                    ]}
              </Select>
              <FormHelperText>{error?.message || ' '}</FormHelperText>
            </>
          )}
          control={control}></Controller>
      </FormControl>
    )
  }
)

SingleSelect.displayName = 'SingleSelect'

export default SingleSelect
